/* Hide scrollbar for IE, Edge and Firefox */
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body * {
  font-family: 'Source Serif Pro', serif;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none!important;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none!important;
}


/* 
BEAUTIFICATION 
*/

.fw-bold {
  font-weight: bold !important;
}

.text-indent {
  text-indent: 10%;
}

.title {
  color: rgb(16, 65, 164);
  text-align: center !important;
  font-weight: 400!important;
}

.text-pink {
  color: rgb(236, 79, 157);
}

.text-shadow {
  text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

.gray-bg{
  background-color:  #f5f5f5;
}

.display-7 {
  font-size: calc(1.325rem + 0.5vw);
  font-weight: 300;
  line-height: 1.2
}

@media(min-width: 1200px) {
  .display-7 {
    font-size: 2.0rem;
  }
}

.display-8 {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 300;
  line-height: 1.2
}

@media(min-width: 1200px) {
  .display-8 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  .shrink-50 {
    zoom:0.5;
  }
}

.navbar-bg {
  background: #141E30;
  background: -webkit-linear-gradient(to top, #243B55, #141E30);
  background: linear-gradient(to top, #243B55, #141E30);
}


.darkblue-grad-bg {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%,  rgba(2,0,36,1) 100%);
}

.clouds-grad-bg{
  background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );
}

.heart {
  position: relative;
  bottom: -2rem;
  animation: rotate 3s infinite linear;
}

.rotate {
  animation: rotate 3s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg)
  }
  100% {
    transform: rotateY(360deg)
  }
}

/* 
Fixing Specific Elements 
*/

.navbar-brand img {
  height: 4rem;
  margin: 0 1.5rem;
}

@media screen and (max-width: 576px) {
  .navbar .navbar-brand {
    margin-left:25%!important;
    margin-right:0!important;
  }
}

.instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

@keyframes slide {
  0%{
    margin-left:-5%
  }
  100%{
    margin-left:-160%
  }
}


.react-multi-carousel-item {
  padding: 0 0.5rem;
}

.react-multi-carousel-img {
  border-radius: 0.25rem;
  height: 50vh!important;
  object-fit: cover;
  margin:0 auto;
  width:100%;
}

.img-fill {
  object-fit: fill!important;
}

.paper-cut-border-before {
  width: 100%;
  border-top: 30px solid transparent;
  border-image: url('../images/paper-cut-top.png') 50 stretch;
}

.paper-cut-border-after {
  width: 100%;
  border-bottom: 30px solid transparent;
  border-image: url('../images/paper-cut-bottom.png') 50 stretch;
}

.images-centered {
  height: 100%!important;
  width: 100%!important;
  object-fit:cover;
}
